

@import url("https://fonts.googleapis.com/css2?family=Playfair+Display+SC:ital,wght@0,400;0,700;0,900;1,400;1,700;1,900&family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap");
html,
body {
  height: 100%;
}

.main-container {
  background-color: #ebeff2;
  height: 100vh;
}

.login-container {
  width: 60%;
  margin: 0 auto;
  background-color: #ffffff;
  border-radius: 1rem;
  overflow: hidden;
  height: 75%;
  /* z-index: 1; */
}
.left-inside {
  width: 35%;
  margin: 0;
  height: 100%;
  padding: 1rem;
  color: white;
  position: relative;
  margin: 0;
  padding: 0;
}

.img-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  padding-bottom: 1rem;
  background-color: rgb(208, 40, 208);
}

.img-bg
 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  
  padding-bottom: 1rem;
}
.img-bg {
  opacity: 0.5;
  z-index: 2;
  mix-blend-mode: multiply;
}
.text-overlap {
 
 
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2; 
  text-align: center;
  color: white; 
}

.right-inside {
  width: 60%;
  height: 100%;
}
.x-line {
  width: 50%;
  height: 4px;
  background-color: #ead2ee;
}
.Bewta {
  font-family: "Playfair Display ", serif;
  
}
.form-heading {
  font-family: Nunito;
  color: rgb(0, 0, 0);
}
.form-content {
  gap: 1rem;
  width: 60%;
}
.form-content input {
  height: 2rem;
  border: none;
  outline: none;
}
.input-label {
  display: block;
  border-radius: 1.5rem;
  border: 1px solid rgb(201, 196, 196);
  overflow: hidden;
  padding: 0;
}

.left-h1 {
  font-family: "Montserrat", sans-serif;
}
.left-p {
  font-family: "Montserrat", sans-serif;
}
.chk-box{
    text-align: center;
    /* width: 80%; */
}
.chk-label{
    color: 
    rgba(33, 37, 41, 0.75);
    font-family: Nunito;
    font-size: 15px;
    width: 40%;
}
.forget{
    font-family: Nunito;
    color: 
    rgba(33, 37, 41, 0.75);
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 0;
}
.Login-btn{
    background-color: #42B4A7;
    color: white;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 400;
    width: 100%;
    border-radius: 1.5rem;
    outline: none;
    border: none;
    
}
.btn-container{
    width: 30%;
    margin-top: 1.5em;
}
.dot{
    width: 5px;
    height: 5px;
    background-color: rgba(33, 37, 41, 0.75);
    border-radius: 50%;
   
}
.footer-p{
    font-family: nunito;
    color: rgba(33, 37, 41, 0.75);
    font-size: 12px;
}

@media(max-width:992px){
  .login-container{
    width: 90%;
  margin: 0 auto;
  background-color: #ffffff;
  border-radius: 1rem;
  overflow: hidden;
  height: 95%;
  }
}

@media(max-width:576px){
  .login-container{
   display: flex;
   flex-direction: column !important;
   height: auto;
  }
  .left-inside, .right-inside {
    width: 100%; 
  }
  .text-overlap{
    margin-left: -4em !important;
  }
  .img-container {
    height: 200px; 
  }
  .text-overlap {
    top: 50%;
    transform: translateY(-50%);
    
  }
  .form-content {
    width: 90%;
    gap: 0.5rem ;
  }
  .btn-container {
    width: 80%;
     margin: 0.5em !important;
  }
  .Login-btn{
    width: 50%;
  }
  .Bewta,.form-heading{
    margin: 0;
  }
  
  
}
